import React, { useLayoutEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import bannerImage from '../../images/header-illustration.png';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './banner.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { formatContentfulText } from '../../helpers/textHelpers';

function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const query = graphql`
    query bannerQuery {
        contentfulBanner {
            buttonText
            contentfulid
            header {
                header
            }
            text {
                text
            }
        }
    }
`;

const Banner = () => {
    const width = useWindowSize();
    const data = useStaticQuery(query).contentfulBanner;

    return (
        <Jumbotron fluid className={'banner'} id={'futurum'}>
            <Container>
                <Row className={'bannerRow'}>
                    <Column sm={12} md={4} lg={5} xl={6} className={'imageColumn'}>
                        <Image src={bannerImage} alt={''} fluid className={'imageColumnImage'} />
                    </Column>
                    <Column sm={12} md={8} lg={7} xl={6} className={'bannerColumn'}>
                        <h1>{formatContentfulText(data.header.header)}</h1>
                        <p className={'bannerText'}>{formatContentfulText(data.text.text)}</p>
                        <AnchorLink offset={width >= 992 ? 90 : 0} href={'#oferta'} className={'btn btn-secondary btn-lg btnBanner'}>
                            {formatContentfulText(data.buttonText)}
                        </AnchorLink>
                    </Column>
                </Row>
            </Container>
        </Jumbotron>
    );
};
export default Banner;
