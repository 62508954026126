import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import './contactSection.scss';
import iconArrow from '../../images/icon-arrow-02.svg';
import iconEmail from '../../images/icon-mail.svg';
import Image from 'react-bootstrap/Image';
import Message from '../message/message';
import { graphql, useStaticQuery } from "gatsby"
import { addBr } from '../../helpers/textHelpers';

const query = graphql`
    query contactQuery {
        contentfulContact {
            secondPersonPhone
            secondPersonName
            secondPersonMail
            firstPersonPhone
            firstPersonName
            firstPersonMail
            mapAddress
            mapText
            title
            address {
                address
            }
        }
    }
`;

const ContactSection = () => {
    const data = useStaticQuery(query).contentfulContact;
    return (
        <>
            <div className={'contactSection'} id={'kontakt'}>
                <Container>
                    <div className={'contactSectionInner'}>
                        <h2 className={'contactSectionHeader h1'}>{data.title}</h2>
                        <Row>
                            <Column sm={12} md={6} xl={5}>
                                <p className={'body2'}>
                                    {data.firstPersonName}
                                    <br />
                                    <span className={'contactSectionPhone'}>tel. {data.firstPersonPhone}</span>
                                </p>
                                <a href={`mailto:${data.firstPersonMail}`} className={'email'}>
                                    <Image src={iconEmail} alt={''} className={'iconEmail'} aria-hidden={true} />
                                    <span>{data.firstPersonMail}</span>
                                </a>
                            </Column>
                            <Column sm={12} md={6} xl={5}>
                                <p className={'body2'}>
                                    {data.secondPersonName}
                                    <br />
                                    <span className={'contactSectionPhone'}>tel. {data.secondPersonPhone}</span>
                                </p>
                                <a href={`mailto:${data.secondPersonMail}`} className={'email'}>
                                    <Image src={iconEmail} alt={''} className={'iconEmail'} aria-hidden={true} />
                                    <span>{data.secondPersonMail}</span>
                                </a>
                            </Column>
                        </Row>
                        <p className={'contactAddress body2'} dangerouslySetInnerHTML={{__html: addBr(data.address.address)}} />
                        <a href={data.mapAddress} className={'contactMap'} target={'_blank'} rel='noopener noreferrer'>
                            <Image src={iconArrow} alt={''} className={'iconArrow'} aria-hidden={true} />
                            <span>{data.mapText}</span>
                        </a>
                    </div>
                </Container>
            </div>
            <Container>
                <Message />
            </Container>
        </>
    );
};

export default ContactSection;
