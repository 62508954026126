import React, { useLayoutEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import './footer.scss';
import logo from '../../images/logo-invert.png';
import arrowUp from '../../images/icon-arrow-up.svg';
import iconFacebook from '../../images/icon-facebook.svg';
import iconInstagram from '../../images/icon-instagram.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { graphql, useStaticQuery } from 'gatsby';

function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const query = graphql`
    query footerQuery {
        contentfulFooter {
            facebookAddress
            instagramAddress
            copyrightTextPart2
            copyrightTextPart1
        }
    }
`;

const Footer = () => {
    const data = useStaticQuery(query).contentfulFooter;
    const width = useWindowSize();

    return (
        <footer className={'footer'}>
            <Container>
                <Row className={'logoRow'}>
                    <Column md={6}>
                        <Image data-src={logo} src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'} alt={'Logo Przedszkola Bajkowego Futurum'} className={'lazyload'} fluid />
                        <noscript>
                            <Image src={logo} alt={'Logo Przedszkola Bajkowego Futurum'} fluid />
                        </noscript>
                    </Column>
                    <Column className={'footerSocialLinksMobileWrapper'}>
                        <div className={'footerSocialLinksMobile'}>
                            <a href={data.facebookAddress} className={'footerSocialLink'} target={'_blank'} rel="noopener noreferrer">
                                <Image src={iconFacebook} alt={''} className={'iconFacebook'} aria-hidden={true} />
                                <span className={'acc-hide'}>Odwiedź nasz profil na Facebooku</span>
                            </a>
                            {/*<a href={data.instagramAddress} className={'footerSocialLink'} target={'_blank'} rel="noopener noreferrer">*/}
                                {/*<Image src={iconInstagram} alt={''} className={'iconInstagram'} />*/}
                                {/*<span className={'acc-hide'}>Odwiedź nasz profil na Instagramie</span>*/}
                            {/*</a>*/}
                        </div>
                    </Column>
                    <Column md={6}>
                        <AnchorLink href={'#futurum'} className={'btn btn-primary upButton'}>
                            <Image src={arrowUp} alt={''} className={'arrowUp'} aria-hidden={true} />
                            Powrót do góry
                        </AnchorLink>
                    </Column>
                </Row>
                <Row>
                    <Column lg={12}>
                        <div className={'footerLinksWrapper'}>
                            <div className={'footerLinks'}>
                                <AnchorLink offset={width >= 992 ? 90 : 0} href={'#o-nas'} className={'footerLink'}>
                                    O nas
                                </AnchorLink>
                                <AnchorLink offset={width >= 992 ? 90 : 0} href={'#oferta'} className={'footerLink'}>
                                    Oferta
                                </AnchorLink>
                                <AnchorLink offset={width >= 992 ? 90 : 0} href={'#galeria'} className={'footerLink'}>
                                    Galeria
                                </AnchorLink>
                                <AnchorLink offset={width >= 992 ? 90 : 0} href={'#kontakt'} className={'footerLink'}>
                                    Kontakt
                                </AnchorLink>
                            </div>
                            <p className={'footerText'}>
                                {data.copyrightTextPart1} |<br className={'footerBr'} /> {data.copyrightTextPart2}
                            </p>
                        </div>

                        <div className={'footerSocialLinks'}>
                            <a href={data.facebookAddress} className={'footerSocialLink'} target={'_blank'} rel="noopener noreferrer">
                                <Image src={iconFacebook} alt={''} className={'iconFacebook'} />
                                <span className={'footerSocialLinkText'}>
                                    Odwiedź nasz profil na&nbsp;<span className={'u-font-medium'}>Facebooku</span>
                                </span>
                            </a>
                            {/*<a href={data.instagramAddress} className={'footerSocialLink'} target={'_blank'} rel="noopener noreferrer">*/}
                                {/*<Image src={iconInstagram} alt={''} className={'iconInstagram'} />*/}
                                {/*<span className={'footerSocialLinkText'}>*/}
                                    {/*Odwiedź nasz profil na&nbsp;<span className={'u-font-medium'}>Instagramie</span>*/}
                                {/*</span>*/}
                            {/*</a>*/}
                        </div>
                    </Column>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
