import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './message.scss';
import { graphql, useStaticQuery } from "gatsby"
import { formatContentfulText } from '../../helpers/textHelpers';

const query = graphql`
    query messageQuery {
        contentfulRecruitment {
            title
            link
            buttonText
            text {
                text
            }
        }
    }
`;

const Message = () => {
    const data = useStaticQuery(query).contentfulRecruitment;
    return (
        <div className={'message'}>
            <Container className={'uh100'}>
                <Row className={'backgroundSectionColumn'}>
                    <div className={'messageColumn messageHeaderColumn'}>
                        <h2 className={'colorWhite'}>{data.title}</h2>
                    </div>
                    <div className={'messageColumn messageTextColumn'}>
                        <p className={`body2 colorWhite`}>{formatContentfulText(data.text.text)}</p>
                    </div>
                    <div className={'messageColumn messageButtonColumn'}>
                      {data.link.indexOf('@') !== -1 ? <a href={`mailto:${data.link}`} className={'messageButton btn btn-primary btn-lg'} target={'_blank'} rel='noopener noreferrer'>
                        {data.buttonText}
                      </a> : <a href={data.link} className={'messageButton btn btn-primary btn-lg'} target={'_blank'} rel='noopener noreferrer'>
                        {data.buttonText}
                      </a>}


                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default Message;
