import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import './backgroundSection.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { addBr } from '../../helpers/textHelpers';

const query = graphql`
    query aboutUsQuery {
        contentfulAboutUs {
            title {
                title
            }
            firstText {
                firstText
            }
            secondText {
                secondText
            }
        }
    }
`;

const BackgroundSection = () => {
    let data = useStaticQuery(query).contentfulAboutUs;

    return (
        <>
            <div className={'backgroundSectionTop'} id={'o-nas'} />
            <div className={'backgroundSection'}>
                <Container className={'uh100'}>
                    <Row className={'backgroundSectionColumn'}>
                        <Column xs={12} lg={6}>
                            <div className={'leftColumn body1'}>
                                <div className={'backgroundSectionMobileContainer container container-md'}>
                                    <h2 dangerouslySetInnerHTML={{ __html: addBr(data.title.title) }} />
                                    <p dangerouslySetInnerHTML={{ __html: addBr(data.firstText.firstText) }} />
                                </div>
                            </div>
                        </Column>
                        <Column xs={12} lg={6}>
                            <div className={'rightColumn body2'}>
                                <div className={'backgroundSectionMobileContainer container container-md'}>
                                    <p dangerouslySetInnerHTML={{ __html: addBr(data.secondText.secondText) }} />
                                </div>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BackgroundSection;
