import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';

const CustomNavbar = ({children}) => {
  const [YOffset, setYOffset] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    setYOffset(window.pageYOffset);
  };

  return (
      <Navbar sticky="top" expand="lg" variant="light" className={`${YOffset < 30 ? 'headerBg' : 'header'}`} collapseOnSelect={true}>{children}</Navbar>
  );
};

export default CustomNavbar;

