/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';


import '../../styles/main/base.scss';
import Header from '../header/header';
import Banner from '../banner/banner';
import Message from "../backgroundSection/backgroundSection";
import StandardSection from "../standardSection/standardSection"
import GallerySection from "../gallerySection/gallerySection"
import ContactSection from "../contactSection/contactSection"
import Footer from "../footer/footer"
import './layout.scss';

const Layout = ({ children }) => {

    return (
        <div className={'layout'}>
            {children}
            <Header />
            <Banner />
            <Message />
            <StandardSection />
            <GallerySection />
            <ContactSection />
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
