let currentElement;
const selectors = 'input, textrea, select, button, a, label, [tabindex]';
const hideFocusClass = 'hidingFocus';

export const hideFocus = (e) => {
    if (e.target && e.target instanceof Element) {
        currentElement = e.target.matches(selectors) ? e.target : e.target.closest(selectors);
        currentElement.classList.add(hideFocusClass);
    }
};

export const showFocus = (e) => {
    if (e.target instanceof Element) {
        currentElement = e.target.matches(selectors) ? e.target : e.target.closest(selectors);

        if (currentElement && (e.keyCode === 9 || (e.keyCode > 36 && e.keyCode < 41))) {
            currentElement.classList.remove(hideFocusClass);
        }
    }
};
