import React, { useEffect, useLayoutEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../images/logo.png';
import Image from 'react-bootstrap/Image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import CustomNavbar from '../navBar/navBar';
import './header.scss';

const Header = () => {
    function useWindowSize() {
        const [size, setSize] = useState(0);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
                if (window.innerwidth >= 992) {
                    closeMenu();
                }
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    if (typeof window !== 'undefined') {
        require('smoothscroll-polyfill').polyfill();
    }
    const width = useWindowSize();
    const [isShown, setIsShown] = useState(false);
    const [scrollWidth, setScrollWidth] = useState(0);
    const focusableElements = typeof window !== 'undefined' ? document.querySelectorAll('.navbar-toggler, .headerElement') : [];
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    const removeLayer = () => {
        document.getElementById('___gatsby').classList.remove('u-no-overflow');
        document.getElementById('___gatsby').style.paddingRight = 0;
        document.getElementById('basic-navbar-nav').style.paddingRight = 0;
        document.removeEventListener('keydown', onMenuKeydown);
    };

    const onMenuItemClick = (e) => {
        e.preventDefault();
        if (isShown && width < 992) {
            removeLayer();
            closeMenu();
        }
        let offset = width >= 992 ? 90 : 0;
        const id = e.currentTarget.getAttribute('href').slice(1);
        const $anchor = document.getElementById(id);
        const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;

        window.scroll({
            top: offsetTop - offset,
            behavior: 'smooth',
        });
        // detect that scroll finished to focus
        // $anchor.focus();
    };
    const openMenu = () => {
        setIsShown(true);
    };

    const closeMenu = () => {
        setIsShown(false);
    };

    const onMenuKeydown = (e) => {
        if (isShown) {
            if (e.key === 'Tab' || e.keyCode === 9) {
                if (e.shiftKey) {
                    /* shift + tab */ if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus();
                        e.preventDefault();
                    }
                } /* tab */ else {
                    if (document.activeElement === lastFocusableElement) {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                }
            } else if (e.key === 'Escape') {
                closeMenu();
            }
        }
    };

    useEffect(() => {
        setScrollWidth(window.innerWidth - document.documentElement.clientWidth);
    }, []);

    useEffect(() => {
        if (isShown) {
            if (width >= 992) {
                document.getElementById('___gatsby').classList.remove('u-no-overflow');
                document.getElementById('___gatsby').style.paddingRight = 0;
                document.getElementById('basic-navbar-nav').style.paddingRight = 0;
            } else {
                document.getElementById('___gatsby').classList.add('u-no-overflow');
                document.getElementById('___gatsby').style.paddingRight = `${scrollWidth}px`;
                document.getElementById('basic-navbar-nav').style.paddingRight = `${scrollWidth}px`;
            }
        }
    }, [width]);

    useEffect(() => {
        if (isShown) {
            document.addEventListener('keydown', onMenuKeydown);
        }
        return function cleanupListener() {
            document.removeEventListener('keydown', onMenuKeydown);
        };
    });

    useEffect(() => {
        if (isShown) {
            document.getElementsByClassName('navbar-toggler ')[0].classList.add('isOpen');
            document.getElementById('___gatsby').classList.add('u-no-overflow');
            document.getElementById('___gatsby').style.paddingRight = `${scrollWidth}px`;
            document.getElementById('basic-navbar-nav').style.paddingRight = `${scrollWidth}px`;
            document.addEventListener('keydown', onMenuKeydown);
        } else {
            document.getElementsByClassName('navbar-toggler ')[0].classList.remove('isOpen');
            removeLayer();
        }
    }, [isShown]);

    return (
        <>
            {/*<div className={'beforeHeader'}></div>*/}
            <CustomNavbar>
                <Container>
                    <AnchorLink href={'#futurum'} className={'navbar-brand'}>
                        <Image src={logo} alt={'Logo Przedszkola Bajkowego Futurum'} className={'headerLogo'} />
                    </AnchorLink>
                    <button
                        aria-controls="basic-navbar-nav"
                        type="button"
                        aria-label="Toggle navigation"
                        className={`navbar-toggler collapsed`}
                        onClick={() => {
                            if (isShown) {
                                document.getElementsByClassName('navbar-toggler ')[0].classList.remove('isOpen');
                                closeMenu();
                            } else {
                                document.getElementsByClassName('navbar-toggler ')[0].classList.add('isOpen');
                                openMenu();
                            }
                        }}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={'container container-md'}>
                        <Navbar.Collapse id="basic-navbar-nav" className={`${isShown ? 'show' : ''}`}>
                            <Nav>
                                <a
                                    href={'#o-nas'}
                                    className={'headerElement nav-link'}
                                    onClick={(e) => {
                                        onMenuItemClick(e);
                                    }}
                                >
                                    O nas
                                </a>
                                <a
                                    href={'#oferta'}
                                    className={'headerElement nav-link'}
                                    onClick={(e) => {
                                        onMenuItemClick(e);
                                    }}
                                >
                                    Oferta
                                </a>
                                <a
                                    href={'#galeria'}
                                    className={'headerElement nav-link'}
                                    onClick={(e) => {
                                        onMenuItemClick(e);
                                    }}
                                >
                                    Galeria
                                </a>
                                <a
                                    href={'#kontakt'}
                                    className={'headerElement nav-link'}
                                    onClick={(e) => {
                                        onMenuItemClick(e);
                                    }}
                                >
                                    Kontakt
                                </a>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </CustomNavbar>
        </>
    );
};

export default Header;
