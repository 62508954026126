import React from 'react';
import Image from 'react-bootstrap/Image';
import './listItem.scss';

const customListItem = ({ url, children, listItemIndex }) => {
    return (
        <li className={`${'listItem'} col col-md-6 col-sm-12`} key={`standardList-${listItemIndex}`}>
            <div className={'listItemImageWrapper'}>
                <Image data-src={url} src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'} alt={''} className={'lazyload listItemImage'} />
                <noscript>
                    <Image src={url} alt={''} className={'listItemImage'} />
                </noscript>
            </div>
            <div className={'listItemText'}>{children}</div>
        </li>
    );
};

export default customListItem;
