import React from 'react';
import Container from 'react-bootstrap/Container';
import CustomListItem from '../listItem/listItem';
import './standardSection.scss';
import { formatContentfulText } from '../../helpers/textHelpers';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
    query featuresQuery {
        contentfulFeatures {
            title
            featureItems {
                title {
                    title
                }
                text {
                    text
                }
                picture {
                    file {
                        url
                    }
                }
            }
        }
        allContentfulAsset {
            nodes {
                id
                localFile {
                    id
                    publicURL
                    url
                }
            }
        }
    }
`;

const StandardSection = () => {
    let data = useStaticQuery(query);
    data.contentfulFeatures.featureItems.map((feature) => {
        let pictureId = feature.picture.id;
        data.allContentfulAsset.nodes.map((asset) => {
            if (asset.id === pictureId) {
                feature.url = asset.localFile.publicURL;
            }
        });
    });
    data = data.contentfulFeatures;

    return (
        <div className={'standardSection'} id={'oferta'}>
            <Container>
                <h2 className={'h1 standardSectionHeader'}>{data.title}</h2>
                <ul className="row">
                    {data.featureItems.map((featureItem, index) => {
                        return (
                            <CustomListItem url={featureItem.url} listItemIndex={index}>
                                <h3>{formatContentfulText(featureItem.title.title)}</h3>
                                {featureItem.text && <p>{formatContentfulText(featureItem.text.text)}</p>}
                            </CustomListItem>
                        );
                    })}
                </ul>
            </Container>
        </div>
    );
};

export default StandardSection;
