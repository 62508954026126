import React, { useEffect } from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import { hideFocus, showFocus } from '../helpers/hideFocus';

const IndexPage = () => {
    useEffect(() => {
        window.addEventListener('mousedown', hideFocus);
        window.addEventListener('keyup', showFocus);

        return () => {
            window.removeEventListener('mousedown', hideFocus);
            window.removeEventListener('keyup', showFocus);
        };
    }, []);

    return (
        <Layout>
            <SEO lang={'pl'} title={'Futurum'} />
        </Layout>
    );
};

export default IndexPage;
