import React, { useEffect, useState, useLayoutEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './gallerySection.scss';
import Button from 'react-bootstrap/Button';
import buttonArrow from '../../images/icon-arrow-03.svg';
import '../../../node_modules/photoswipe/dist/photoswipe.css';
import '../../../node_modules/photoswipe/dist/default-skin/default-skin.css';
import PhotoSwipe from '../../../node_modules/photoswipe/dist/photoswipe.js';
import PhotoSwipeUI_Default from '../../../node_modules/photoswipe/dist/photoswipe-ui-default.js';
import { graphql, useStaticQuery } from 'gatsby';

const ImageContainer = ({ className = '', url, index, size, children }) => {
    return (
        <div className={`imageContainer ${className}`}>
            <div className={'imageContainerInner'}>
                <figure data-index={index}>
                    <a href={url} data-size={size}>
                        {children}
                        <span className={'acc-hide'}>Zobacz zdjęcie {index + 1}</span>
                    </a>
                </figure>
            </div>
        </div>
    );
};

function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const query = graphql`
    query galleryQuery {
        contentfulGallery {
            title
            text {
                text
            }
            galleryItems {
                id
                mainPhoto {
                    file {
                        details {
                            image {
                                width
                                height
                            }
                        }
                        url
                    }
                }
                thumbnailPhoto {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
        allContentfulAsset {
            nodes {
                id
                localFile {
                    id
                    publicURL
                    url
                }
            }
        }
    }
`;

const GallerySection = () => {
    const width = useWindowSize();

    let data = useStaticQuery(query);
    data.contentfulGallery.galleryItems.map((galleryItem) => {
        let mainPhotoId = galleryItem.mainPhoto.id;
        let thumbnailPhotoId = '';
        if (galleryItem.thumbnailPhoto) {
            thumbnailPhotoId = galleryItem.thumbnailPhoto.id;
        }
        data.allContentfulAsset.nodes.map((asset) => {
            if (asset.id === mainPhotoId) {
                galleryItem.mainPhoto.url = asset.localFile.publicURL;
            }
            if (galleryItem.thumbnailPhoto) {
                if (asset.id === thumbnailPhotoId) {
                    galleryItem.thumbnailPhoto.url = asset.localFile.publicURL;
                }
            }
        });
    });
    data = data.contentfulGallery;

    if (typeof window !== 'undefined') {
        if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
        }

        if (!Element.prototype.closest) {
            Element.prototype.closest = function(s) {
                var el = this;

                do {
                    if (el.matches(s)) return el;
                    el = el.parentElement || el.parentNode;
                } while (el !== null && el.nodeType === 1);
                return null;
            };
        }
    }

    var initPhotoSwipeFromDOM = function(gallerySelector) {
        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function(el) {
            var thumbElements = el.getElementsByTagName('*'),
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                size,
                item;

            for (var i = 0; i < numNodes; i++) {
                figureEl = thumbElements[i]; // <figure> element

                // include only element nodes
                if (figureEl.nodeType !== 1) {
                    continue;
                }

                linkEl = figureEl.children[0]; // <a> element

                if (linkEl && linkEl.getAttribute('data-size')) {
                    size = linkEl.getAttribute('data-size').split('x');

                    // create slide object
                    item = {
                        src: linkEl.getAttribute('href'),
                        w: parseInt(size[0], 10),
                        h: parseInt(size[1], 10),
                    };

                    if (figureEl.children.length > 1) {
                        // <figcaption> content
                        item.title = figureEl.children[1].innerHTML;
                    }

                    if (linkEl.children.length > 0) {
                        // <img> thumbnail element, retrieving thumbnail url
                        item.msrc = linkEl.children[0].getAttribute('src');
                    }

                    item.el = figureEl; // save link to element for getThumbBoundsFn
                    items.push(item);
                }
            }

            return items;
        };

        // find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function(e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : (e.returnValue = false);

            var eTarget = e.target || e.srcElement;

            // find root element of slide
            var clickedListItem = closest(eTarget, function(el) {
                return el.tagName && (el.tagName.toUpperCase() === 'FIGURE' || el.className === 'galleryButtonWrapper');
            });

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = clickedListItem.parentNode,
                index;


            index = clickedListItem.getAttribute('data-index');

            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
            var pswpElement = document.querySelectorAll('.pswp')[0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement.closest('.gallerySection'));

            // define options (if needed)
            options = {
                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                getThumbBoundsFn: function(index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect();

                    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                },
                history: false,
            };

            options.index = parseInt(index, 10);

            // exit if index not found
            if (isNaN(options.index)) {
                return;
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0;
                options.hideAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector);

        for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }
    };

    useEffect(() => {
        initPhotoSwipeFromDOM('.gallerySection');
    }, []);

    return (
        <div className={'gallerySection'} id={'galeria'}>
            <Container>
                <Row>
                    <Column lg={6} className={'titleColumn'}>
                        <h2 className={'gallerySectionHeader'}>{data.title}</h2>
                        <h3 className={'gallerySectionSubheader h2'}>{data.text.text}</h3>
                    </Column>
                </Row>
                <Row className={'galleryRow'}>
                    <Column lg={6} className={'galleryLeftColumn'}>
                        <Row className={'galleryInnerRow'}>
                            <Column lg={8} className={'galleryInnerColumn galleryInnerColumnCustom'}>
                                <ImageContainer
                                    url={data.galleryItems[0].mainPhoto.url}
                                    index={0}
                                    size={`${data.galleryItems[0].mainPhoto.file.details.image.width}x${data.galleryItems[0].mainPhoto.file.details.image.height}`}
                                    className={'galleryImageFirst'}
                                >
                                    <Image
                                        data-src={data.galleryItems[0].thumbnailPhoto.url}
                                        src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                        alt={''}
                                        className={'lazyload galleryImage'}
                                    />
                                    <noscript>
                                        <Image src={data.galleryItems[0].thumbnailPhoto.url} alt={''} className={'galleryImage'} />
                                    </noscript>
                                </ImageContainer>
                            </Column>
                            <Column lg={4} className={'galleryInnerColumn galleryInnerColumnCustom'}>
                                <ImageContainer
                                    url={data.galleryItems[1].mainPhoto.url}
                                    index={1}
                                    size={`${data.galleryItems[1].mainPhoto.file.details.image.width}x${data.galleryItems[1].mainPhoto.file.details.image.height}`}
                                >
                                    <Image
                                        data-src={data.galleryItems[1].thumbnailPhoto.url}
                                        src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                        alt={''}
                                        className={'lazyload galleryImage'}
                                    />
                                    <noscript>
                                        <Image src={data.galleryItems[1].thumbnailPhoto.url} alt={''} className={'galleryImage'} />
                                    </noscript>
                                </ImageContainer>
                                <ImageContainer
                                    url={data.galleryItems[2].mainPhoto.url}
                                    index={2}
                                    size={`${data.galleryItems[2].mainPhoto.file.details.image.width}x${data.galleryItems[2].mainPhoto.file.details.image.height}`}
                                    className={'galleryImagePaddingTop'}
                                >
                                    <Image
                                        data-src={data.galleryItems[2].thumbnailPhoto.url}
                                        alt={''}
                                        src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                        className={'lazyload galleryImage'}
                                    />
                                    <noscript>
                                        <Image src={data.galleryItems[2].thumbnailPhoto.url} alt={''} className={'galleryImage'} />
                                    </noscript>
                                </ImageContainer>
                            </Column>
                        </Row>
                        <Row className={'galleryInnerRow galleryImagePaddingTop'}>
                            <Column lg={5} xs={6} className={'galleryInnerColumn'}>
                                <ImageContainer
                                    url={data.galleryItems[3].mainPhoto.url}
                                    index={3}
                                    size={`${data.galleryItems[3].mainPhoto.file.details.image.width}x${data.galleryItems[3].mainPhoto.file.details.image.height}`}
                                    className={'imageContainer160230'}
                                >
                                    <Image
                                        data-src={data.galleryItems[3].thumbnailPhoto.url}
                                        src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                        alt={''}
                                        className={'lazyload galleryImage'}
                                    />
                                    <noscript>
                                        <Image src={data.galleryItems[3].thumbnailPhoto.url} alt={''} className={'galleryImage'} />
                                    </noscript>
                                </ImageContainer>
                            </Column>
                            <Column lg={7} xs={6} className={'galleryInnerColumn galleryInnerColumnCustom'}>
                                <ImageContainer
                                    url={data.galleryItems[4].mainPhoto.url}
                                    index={4}
                                    size={`${data.galleryItems[4].mainPhoto.file.details.image.width}x${data.galleryItems[4].mainPhoto.file.details.image.height}`}
                                    className={'imageContainer180310'}
                                >
                                    <Image
                                        data-src={data.galleryItems[4].thumbnailPhoto.url}
                                        src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                        alt={''}
                                        className={'lazyload galleryImage'}
                                    />
                                    <noscript>
                                        <Image src={data.galleryItems[4].thumbnailPhoto.url} alt={''} className={'galleryImage'} />
                                    </noscript>
                                </ImageContainer>
                            </Column>
                        </Row>
                    </Column>
                    <Column lg={6} className={'galleryRightColumn'}>
                        <Row className={'galleryInnerRow'}>
                            <Column lg={7} className={'galleryInnerColumn galleryHiddenMobile'}>
                                <ImageContainer
                                    url={data.galleryItems[5].mainPhoto.url}
                                    index={5}
                                    size={`${data.galleryItems[5].mainPhoto.file.details.image.width}x${data.galleryItems[5].mainPhoto.file.details.image.height}`}
                                >
                                    <Image
                                        data-src={data.galleryItems[5].thumbnailPhoto.url}
                                        src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                        alt={''}
                                        className={'lazyload galleryImage'}
                                    />
                                    <noscript>
                                        <Image src={data.galleryItems[5].thumbnailPhoto.url} alt={''} className={'galleryImage'} />
                                    </noscript>
                                </ImageContainer>
                            </Column>
                        </Row>
                        <Row className={'galleryInnerRow galleryImagePaddingTop'}>
                            <Column lg={4} className={'galleryInnerColumn galleryHiddenMobile'}>
                                <ImageContainer
                                    url={data.galleryItems[6].mainPhoto.url}
                                    index={6}
                                    size={`${data.galleryItems[6].mainPhoto.file.details.image.width}x${data.galleryItems[6].mainPhoto.file.details.image.height}`}
                                >
                                    <Image
                                        data-src={data.galleryItems[6].thumbnailPhoto.url}
                                        src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                        alt={''}
                                        className={'lazyload galleryImage'}
                                    />
                                    <noscript>
                                        <Image src={data.galleryItems[6].thumbnailPhoto.url} alt={''} className={'galleryImage'} />
                                    </noscript>
                                </ImageContainer>
                            </Column>
                            <Column lg={8} className={'galleryInnerColumn galleryInnerColumnButton'}>
                                <div className={'galleryButtonWrapper'} data-index={width >= 992 ? (data.galleryItems.length > 7 ? 7 : 0) : 5}>
                                    <Button className={'galleryButton'}>
                                        <Image src={buttonArrow} alt={''} className={'buttonArrow'} aria-hidden={true} />
                                        Pokaż więcej zdjęć
                                    </Button>
                                </div>
                            </Column>
                        </Row>
                    </Column>
                </Row>
                {data.galleryItems.length > 7 && (
                    <div className={'galleryElementsHidden'}>
                        {data.galleryItems.map((item, index) => {
                            if (index > 6) {
                                return (
                                    <figure data-index={index + 1}>
                                        <a
                                            href={data.galleryItems[index].mainPhoto.url}
                                            data-size={`${data.galleryItems[index].mainPhoto.file.details.image.width}x${data.galleryItems[index].mainPhoto.file.details.image.height}`}
                                        >
                                            <Image
                                                alt={''}
                                                data-src={data.galleryItems[index].mainPhoto.url}
                                                src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                                                className={'lazyload'}
                                            />
                                            <span className={'acc-hide'}>Zobacz zdjęcie {index + 1}</span>
                                        </a>
                                    </figure>
                                );
                            }
                        })}
                    </div>
                )}
            </Container>
            <div className="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="pswp__bg" />

                <div className="pswp__scroll-wrap">
                    <div className="pswp__container">
                        <div className="pswp__item" />
                        <div className="pswp__item" />
                        <div className="pswp__item" />
                    </div>

                    <div className="pswp__ui pswp__ui--hidden">
                        <div className="pswp__top-bar">
                            <div className="pswp__counter" />

                            <button className="pswp__button pswp__button--close" title="Close (Esc)" />

                            <button className="pswp__button pswp__button--share" title="Share" />

                            <button className="pswp__button pswp__button--fs" title="Toggle fullscreen" />

                            <button className="pswp__button pswp__button--zoom" title="Zoom in/out" />

                            <div className="pswp__preloader">
                                <div className="pswp__preloader__icn">
                                    <div className="pswp__preloader__cut">
                                        <div className="pswp__preloader__donut" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                            <div className="pswp__share-tooltip" />
                        </div>

                        <button className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)" />

                        <button className="pswp__button pswp__button--arrow--right" title="Next (arrow right)" />

                        <div className="pswp__caption">
                            <div className="pswp__caption__center" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GallerySection;
